import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Route,Routes,Navigate } from 'react-router-dom'
import Login from '../Auth/Login'

type Props={
    children?: any,
    isLogin:any,
}

const PrivateRouters:React.FC<Props> = ({isLogin,children}) => {

    if (!isLogin) {
      return <Navigate to='/login' replace />;
    }
  
    return children;
  };
export default PrivateRouters