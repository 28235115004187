import React from 'react'

export const Homepage:React.FC = () => {
  return (
    <div>
      <h1>This is for Demo project</h1>
      <p>Technologies used: React,Typescript,Redux-toolkit(For Redux),Persist Storage,JWT Auth</p>
      <h4>Functionality</h4>
      <br/>
      <p>Login,Logout,Register</p>
      <p>Home And About page(can be replace with any protected page)</p>
      <h5>Routes Handling</h5>
      <p>/home and /about : Home and About page which are Protected ,by which means we can only access if used is logged in</p> 
      <p>Login and Register are public and can be access by anyone</p>
      <p> used ProtectsRoutes mechanism</p>
      <h5>additional Features</h5>
      <p>Every page can be accessed by without reload page(which is reactJs's first advantage)</p>
      <p> in case if user reload page. user will not have to be login again due to use of persistant starage by redux.</p>
    </div>
  )
}
