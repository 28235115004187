import React, { useEffect } from 'react'
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './login.css';
import Layout from './Layout';
import { Link } from 'react-router-dom';

const Register:React.FC = () => {

  const [showPassword1, setShowPassword1] = React.useState(false);
    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
    const handleMouseDownPassword1 = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const [showPassword2, setShowPassword2] = React.useState(false);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword2 = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    
    const [state, setState] = React.useState({
      username: "",
      email:"",
      password:"",
      confirm_password:"",
      error:false,
      errorText:""

    })
    const handleChange=(e:any)=>{
      setState({...state,[e.target.name]:e.target.value})
      
    }
    useEffect(()=>{
      if (state.email !== ''){
        const isValid:any=validateEmail(state.email)
        if (!isValid){
          setState({...state,error:true,errorText:"Invalid Email"})
        }
        else{
          setState({...state,error:false,errorText:""})
        }
      }
      else{
        setState({...state,error:false,errorText:""})

      }
     
    },[state.email])
    useEffect(()=>{
      if (state.password !=='' && state.confirm_password !== ''){
        if (state.password !== state.confirm_password){
          setState({...state,error:true,errorText:"Password didn't Match"})
        }
        else{
          setState({...state,error:false,errorText:""})

        }
      }

    },[state.password,state.confirm_password])

    const handleSubmit=(e:any)=>{
      e.preventDefault();
      console.log(">>>>",state.username,state.password)
    }

    
    function validateEmail(email: string) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }


  return (
    <Layout title={"Register"} error={state.error} errorText={state.errorText}>

      <form onSubmit={handleSubmit}>
    <Box sx={{py:1}}>
      <TextField fullWidth label="Username" id="username" name="username" onChange={handleChange}/>
    </Box>
    <Box sx={{py:1}}>
      <TextField fullWidth label="Email" id="email" name="email" onChange={handleChange}/>
    </Box>
    <Box sx={{py:1}}>
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
      <OutlinedInput
      onChange={handleChange}
      fullWidth
      name='password'
        id="outlined-adornment-password"
        type={showPassword1 ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword1}
              onMouseDown={handleMouseDownPassword1}
              edge="end"
            >
              {showPassword1 ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
    </FormControl>
    </Box>
    <Box sx={{py:1}}>
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
      <OutlinedInput
      onChange={handleChange}
      fullWidth
      name='confirm_password'
        id="outlined-adornment-password"
        type={showPassword2 ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword2}
              onMouseDown={handleMouseDownPassword2}
              edge="end"
            >
              {showPassword2 ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
    </FormControl>
    </Box>
    <Box>
        <Link className='account_links' to="/login">Already have Account?</Link>
        </Box>
    <Box>

    <Button type="submit" variant="contained">Register</Button>
    </Box>
    </form>
    </Layout>
  )
}

export default Register