import React from 'react'
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

type Props={
  children?: any;
  title:String,
  error:boolean,
  errorText:String,
}

const Layout:React.FC<Props> = ({children,title,error,errorText}) => {
  return (
    <Box sx={{ flexGrow: 1}}>
    <Grid container className='main_content_box' >
      <Grid item className='login_main'  >
      <Box className='login_header_text' >{title} </Box>
      {error && <Box sx={{ color: 'error.main' }}>{errorText}</Box>}

      {children}
      </Grid>
    </Grid>
  </Box>
      
  )
}

export default Layout