import React from 'react'
import Box from '@mui/material/Box';

import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './login.css';
import Layout from './Layout';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setCredentials } from '../Store/Slices/AuthSlice';
import { useLoginMutation } from '../Store/Slices/authApiSlice'


const Login:React.FC = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [login, { isLoading }] = useLoginMutation()
    const dispatch = useDispatch()


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    const navigate=useNavigate()

    const [state, setState] = React.useState({
      username: "",
      password:"",
      error:false,
      errorText:""
    })

    const handleChange=(e:any)=>{
      setState({...state,[e.target.name]:e.target.value})
    }

    const handleSubmit=async(e:any)=>{
      e.preventDefault();
      if (state.username==='admin' && state.password==='admin'){
        
        
        try {
          const username=state.username
          const password=state.password
          // uncomment this code if you want to use login by jwt

          // const userData = await login({username,password }).unwrap()
         
          // remove this code , its just for demo
         const userData={
                "refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY3Mzg1OTk4OCwiaWF0IjoxNjcyNjUwMzg4LCJqdGkiOiJhNjZmZTMyNGZmZTg0ODU0ODFjNjQxY2QzN2U0YWI2ZiIsInVzZXJfaWQiOjh9.W2f20WgRWF6jubMXh-w2DQegeFspy4yc6bGW53vd3xM",
                "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjcyNjUwNjg4LCJpYXQiOjE2NzI2NTAzODgsImp0aSI6IjY0OWY0ZmI5OThjYjQ2YjBiZjUzNjhjYmNkODQyOWNiIiwidXNlcl9pZCI6OH0.feyCCJ-5CCenXlrRRLSia6tOIJAAw1_1ShDrUgFVAYM",
                "user": "admin"
            }
          // console.log(">>>>>>>userData",userData)

          dispatch(setCredentials({ ...userData, username }))
          // navigate('/welcome')
          navigate('/')

      } catch (err) {
        console.log(">>>eerrr",err)
      }

      }
      else{
        setState({...state,error:true,errorText:"Username or Password is invalid"})
      }
    }

  return (
  
      <Layout title="Login" error={state.error} errorText={state.errorText}>
        <form onSubmit={handleSubmit}>
        <Box sx={{py:1}}>
        <TextField fullWidth label="Username" value={state.username} id="username" name="username" onChange={(e)=>handleChange(e)}
/>
        </Box>
        <Box sx={{py:1}}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            onChange={(e)=>handleChange(e)}
            value={state.password}
          fullWidth
            id="outlined-adornment-password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        </Box>
        <Box>
        <Link className='account_links' to="/register">Create New Account</Link>
        </Box>
        <Box>
        <Button type='submit' variant="contained">Login</Button>
        </Box>
        </form>
        </Layout>
      
  )
}

export default Login