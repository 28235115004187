import React from 'react'
import { useSelector } from 'react-redux';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import Login from '../Auth/Login';
import Register from '../Auth/Register';
import About from '../Dashboard/About';
import { Homepage } from '../Dashboard/Homepage';
import PrivateRouters from './PrivateRouters';

const RoutersChecker:React.FC = () => {
  const isLogin=useSelector((state:any)=>state.auth.token)
  return (
    <>
        <Routes>
                <Route  path='/' element={
                                <PrivateRouters isLogin={isLogin}>
                                  < Homepage/>
                                </PrivateRouters>}></Route>
                <Route path='/about' element={
                              <PrivateRouters isLogin={isLogin}>
                              < About />
                            </PrivateRouters>
                }></Route>
                <Route path='/login' element={< Login />}></Route>
                <Route path='/register' element={< Register />}></Route>
                <Route path="*" element={<p>There's nothing here: 404!</p>} />

          </Routes>
      </>
  )
}

export default RoutersChecker