import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

import Header from './Header/Header';
import { Box } from '@mui/system';
import RoutersChecker from './Routers/RoutersChecker';
import { Toolbar } from '@mui/material';

import { Provider } from 'react-redux';
import { store,persistor } from './Store/store';
import { PersistGate } from 'redux-persist/integration/react';

function App() {

  return (
    <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>

      <Router>
      <Header/>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar/>
          <RoutersChecker/>
          </Box>
        </Router>
        </PersistGate>
    </Provider>
  );
}

export default App;
