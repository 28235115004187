import React, { useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import { Link } from 'react-router-dom';
import './Header.css'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../Store/Slices/AuthSlice';

interface Props {
  window?: () => Window;
}
const drawerWidth = 240;

type menuItem={
  menu_name:String|undefined,
  menu_url:any,
  
}

function Header(props:Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isLogin=useSelector((state:any)=>state.auth.token)
  const dispatch=useDispatch()

  const privateRouter = [{menu_name:'Home',menu_url:"/"},
  {menu_name:'About',menu_url:"/about"},              
  {menu_name:'Logout',menu_url:"/logout"},
];
const publicRouter=[ {menu_name:'Login',menu_url:"/login"},
  {menu_name:'Register',menu_url:"/register"}]



  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLogout=()=>{
    dispatch(logOut())

    }

  const container = window !== undefined ? () => window().document.body : undefined;


  const drawer=(
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
      
        {isLogin ?
          privateRouter.map((item:menuItem,index:number) => (
          
            <ListItem key={index} disablePadding>
              <ListItemButton sx={{ textAlign: 'center',display:'flow-root' }}>
              {item.menu_name === 'Logout'?
              <ListItemText onClick={()=>handleLogout()} primary={item.menu_name} />
              :
              <Link className='all_header_links_sidebar' to={item.menu_url}><ListItemText primary={item.menu_name} /></Link>
              }
              </ListItemButton>
            </ListItem>
  
          ))
        :
           publicRouter.map((item:menuItem,index:number) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
            <Link className='all_header_links_sidebar'  to={item.menu_url}><ListItemText primary={item.menu_name} /></Link>
            </ListItemButton>
          </ListItem>
        ))
           }
        
      </List>
    </Box>
  );
  
  
  
  return (
    <>
     <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0 }}
          >
            MUI                    
             {/* // custom image can be place here    */}
          </Typography>

          <Box sx={{ display: { xs: 'none', sm: 'block'},marginLeft:'auto' }}>
        
            {isLogin ?
                privateRouter.map((item:menuItem,index:number) => (
                  <Button key={index} sx={{ color: '#fff' }}>
                   {item.menu_name === 'Logout'?
                    <ListItemText onClick={()=>handleLogout()} primary={item.menu_name} />
                    :
                    <Link className='all_header_links' to={item.menu_url}><ListItemText primary={item.menu_name} /></Link>
                    }
                  </Button>
                ))
                :
                publicRouter.map((item:menuItem,index:number) => (
              <Button key={index} sx={{ color: '#fff' }}>
              <Link className='all_header_links' to={item.menu_url}>{item.menu_name}</Link>
            </Button>
            ))
        }
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      </>
  )
}

export default Header