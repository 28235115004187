import { configureStore } from "@reduxjs/toolkit"
import { customFetchBase } from "../Api/customFetchBase"
import authSlice from './Slices/AuthSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';


const persistConfig = {
    key: 'root',
    storage,
  }



const reducers = combineReducers({
    [customFetchBase.reducerPath]: customFetchBase.reducer,
    auth: authSlice
  });


const persistedReducer = persistReducer(persistConfig,reducers )


export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([customFetchBase.middleware,]),
    devTools: true
})

export const persistor = persistStore(store)
